import {
  type ThemeConfig,
  ThemeDirection,
  extendTheme,
} from "@chakra-ui/react";
import { ChakraTheme } from "@chakra-ui/theme/dist/theme.types";

const direction: ThemeDirection = "rtl";

const config: ThemeConfig = {
  cssVarPrefix: "eren",
  initialColorMode: "light",
  useSystemColorMode: false,
};

/**
 * Override default styles with our custom theme.
 *
 * The complete list of default Chakra styles can be found here:
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src
 */
const theme = {
  config,
  direction,
  fonts: {
    heading: `'Overpass Variable', sans-serif`,
    body: `'Overpass Variable', sans-serif`,
  },
  semanticTokens: {
    colors: {
      error: "red.500",
      primary: "estherianGold",
      estherianGold: "rgb(187, 139, 51)",
      estherianPurple: "rgba(32,26,64,1)",
    },
    colorSchemas: {
      primary: "estherianGold.500",
      estherianGold: {
        50: "rgb(255, 245, 230)",
        100: "rgb(255, 235, 204)",
        200: "rgb(255, 224, 179)",
        300: "rgb(255, 214, 153)",
        400: "rgb(255, 204, 128)",
        500: "rgb(255, 194, 102)",
        600: "rgb(255, 184, 77)",
        700: "rgb(255, 173, 51)",
        800: "rgb(255, 163, 26)",
        900: "rgb(255, 153, 0)",
      },
    },
  },
} as Partial<ChakraTheme>;

export default extendTheme(theme);
