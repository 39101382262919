module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Estherian Clinic","short_name":"Estherian Clinic","start_url":"/","background_color":"#f7f0eb","theme_color":"#201a40","display":"standalone","icon":"src/assets/logo/estherian-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e48d6b071c9134e20500bffac82f429"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ar","fa","fr","it","ru"],"defaultLanguage":"en","siteUrl":"https://r.estherian.com","redirect":false,"i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ar","fa","fr","it","ru"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
